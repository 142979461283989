body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

:root {
  --pickup-green: rgb(170, 194, 127);
  --pickup-green-disabled: rgba(170, 194, 127, 0.7);
}

body {
  font-family: Poppins, sans-serif;
  background-color: var(--pickup-green);
}

.App {
  min-height: 100vh;
  background-color: white;
}
.greeting {
  padding: 1em 0;
  font-weight: bold;
}
.order-form {
  padding: 1em 2em;
}
.product, .pickup-day, .pickup-time {
  display: flex;
  justify-content: space-between;
}
.product { 
  margin: 2px 4px;
  align-items: center;
  padding: 0 2px;
}
.product-name {
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  font-weight: 600;
}
.description {
  display: block;
  font-weight: normal;
  padding-right: 4px;
}
.product-quantity {
  display: flex;
  align-items: center;
}
.product-quantity-count input {
  width: 1em;
  text-align: center;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid var(--pickup-green);  
}
.button {
  border-radius: 0.4em;
  padding: 0.4em 0.8em;
  background-color: var(--pickup-green);
  margin: 0 0.1em;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
}
.button-increment, .button-decrement {
  padding: 0.2em 0.6em;
}

.pickup-schedule {
  padding: 8px 0;
}
.pickup-schedule .greeting {
  padding-bottom: 16px;
}
.pickup-schedule div {
  padding: 2px 4px;
}

img {
  max-width: 100%;
  height: auto;
}

select {
  width: 160px;
  padding: 8px 16px;
  border: 1px solid var(--pickup-green);
  border-radius: 8px;
  -webkit-appearance: none;
  padding-right: 24px;
  font-size: 16px;
  color: rgb(20,20,20);
}
.select-wrapper {
  position: relative;
  display: inline-block;
}
.select-wrapper:after {
  content: '\0025BC';
  font: normal normal normal 14px/1;
  color: var(--pickup-green);
  right: 14px;
  top: -4px;
  height: 26px;
  padding: 15px 0px 0px 8px;
  position: absolute;
  pointer-events: none;
}

.cart-footer { 
  background-color: var(--pickup-green);
  color: white;
  position: fixed;
  width: 100%;
  height: 48px;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
}
.cart-footer div {
  padding: 0.25em 0.5em;
}
.top-nav {
  height: 60px;
  background-color: var(--pickup-green);
  display: flex;
  padding: 4px 16px;
}
.logo img {
  width: 56px;
  height: auto;
}
.button-decrement {
  margin-left: 8px;
}
.description {
  font-size: 10px;
}
.button:disabled, .button[disabled] {
  background-color: var(--pickup-green-disabled);
  color: rgb(80,80,80);
}